import React from "react";

// Customizable Area Start
import {
  createTheme,
} from "@mui/material/styles";
import ForgotPasswordController, {
    Props,
  } from "./ForgotPasswordController";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
     // Customizable Area Start
      // Customizable Area End
    return (
    // Customizable Area Start
      //Merge Engine DefaultContainer
      <p style={webStyle.textColor}>Forgot password</p>
      //Merge Engine End DefaultContainer
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
    textColor: {
        color: 'red'
    }
};
// Customizable Area End